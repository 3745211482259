.container {
    display: grid;
    justify-content: center;
    position: static;
}

.header {
    text-align: center;
    font-size: 36px;
    scroll-margin: 100px;
}

.description {
    text-align: justify;
    width: 1100px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
}

.detail-page-container {
    display: grid;
    justify-content: center;
    position: relative;
    min-height: calc(100vh - 130px);
}

.equipment-detail-container {
    display: flex;
    justify-content: flex-end;
}

.equipment-title {
    justify-content: space-between;
    width: 260px;
}

.equipment-title-text:not(:empty) {
    font-weight: bold;
    font-size: 24px;
}

.equipment-value {
    justify-content: space-between;
    width: 420px;
}

.equipment-value-text:not(:empty) {
    font-size: 24px;
}

.detail-table-container {
    display: flex;
    justify-content: center;
}

.detail-chart-container {
    display: flex;
    justify-content: center;
}

.footer {
    background-color: #dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 100%;
    bottom: 0;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 9999;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    position: relative;
}