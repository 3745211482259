.container {
    display: grid;
    justify-content: center;
    position: static;
}

.header {
    text-align: center;
    font-size: 36px;
    scroll-margin: 100px;
}

.description {
    text-align: justify;
    width: 1100px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
}

.equipment-dropdown-container {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-left: 150px;
    margin-right: 150px;
}

.equipment-select-dropdown {
    width: 250px;
    z-index: 5;
}

.picture-container {
    position: relative;
    width: 480px;
    display: flex;
    justify-content: center;
}

.picture {
    height: 300px;
}

.equipment-detail-container {
    display: flex;
    justify-content: center;
}

.equipment-title {
    justify-content: space-between;
    width: 260px;
}

.equipment-title-text:not(:empty) {
    font-weight: bold;
    font-size: 24px;
}

.equipment-value {
    justify-content: space-between;
    width: 410px;
}

.equipment-value-text:not(:empty) {
    font-size: 24px;
}

.equipment-table-container {
    display: flex;
    justify-content: center;
}

.equipment-collapsible-button {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 6px;
    margin-left: 450px;
    margin-right: 450px;
}

.equipment-collapsible-button:hover {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24), 0 4px 4px 0 rgba(0,0,0,0.19);
}
