.logout-button {
    background-color: #6200ee;
    color: #ffffff;
    font-size: 16px;
    padding: 6px 24px;
    width: 150px;
    border-width: 0;
    border-radius: 6px;
}

.logout-button:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24), 0 8px 12px 0 rgba(0,0,0,0.19);
}