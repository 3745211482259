.container {
    display: grid;
    justify-content: center;
    position: static;
}

.header {
    text-align: center;
    font-size: 36px;
    scroll-margin: 100px;
}

.description {
    text-align: justify;
    width: 1100px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
}

.spectrum-dropdown-container {
    display: flex;
    justify-content: space-around;
    padding-left: 100px;
    padding-right: 100px;
}

.spectrum-select-dropdown {
    width: 250px;
}

.spectrum-multi-select-dropdown {
    width: 500px;
}

.spectrum-chart-container {
    display: flex;
    justify-content: center;
}

.spectrum-footer {
    background-color: #dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 100%;
    bottom: 0;
    z-index: 9999;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    position: relative;
}