.report-page-container {
    display: flex;
}

.table-of-content {
    width: 20%;
    background-color: #dedede;
    height: calc(100vh - 80px);
    display: block;
    position: fixed;
    padding-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 9999;
    overflow-y: scroll;
}

.table-of-content-closed {
    width: 20%;
    left: -20%;
    background-color: #dedede;
    height: 100vh;
    display: block;
    position: fixed;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 9999;
}

.report-container {
    margin-left: 20%;
    width: 80%;
}

.report-container-full {
    width: 100%;
}

.content-header {
    text-align: start;
    font-size: 26px;
}

.content {
    text-align: start;
    font-size: 16px;
    color: black;
    width: 100%;
    margin-top: 7px;
    display: inline-block;
}

.toggle-button-container {
    width: 40px;
    height: 40px;
    background-color: #dedede;
    position: fixed;
    left: 20%;
    z-index: 9999;
    padding: 7px 7px;
}

.toggle-button-container-closed {
    width: 40px;
    height: 40px;
    background-color: #dedede;
    position: fixed;
    left: 0;
    z-index: 9999;
    padding: 7px 7px;
}