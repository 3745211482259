.form {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.input {
    width: calc(100% - 100px);
    height: 38px;
    border-width: 0px;
    border-radius: 4px;
    padding-left: 10px;
    transition: 0.3s all;
    font-size: 14px;
}

.blue-button {
    background-color: #0096ff;
    color: #ffffff;
    font-size: 14px;
    padding: 8px 20px;
    width: 90px;
    height: 38px;
    border-width: 0;
    border-radius: 6px;
}

.blue-button:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24), 0 4px 4px 0 rgba(0,0,0,0.19);
}