.sidebar {
    background-color: #dedede;
    display: flex;
    justify-content: center;
    height: 80px;
    position: sticky;
    top: 0;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 9999;
}

.sidebar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 340px;
}

.menu-bar {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #060b26;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 5;
    left: -100%;
    transition: 850ms;
    z-index: 9999;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-self: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 0px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #1a83ff;
}

.nav-menu-items {
    width: 100%;
}

span {
    margin-left: 16px;
}