.image {
    height: 300px;
}

.image-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 480px;
    height: 300px;
}

.image-input {
    width: 480px;
    height: 35px;
}