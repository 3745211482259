.container {
    display: grid;
    justify-content: center;
    position: static;
}

.header {
    text-align: center;
    font-size: 36px;
    scroll-margin: 100px;
}

.sub-header {
    text-align: center;
    font-size: 28px;
}

.description {
    text-align: justify;
    width: 1100px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
}

.point-dropdown-container {
    display: flex;
    justify-content: space-around;
}

.point-select-dropdown {
    width: 250px;
    z-index: 5;
}

.feature-dropdown-container {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
}

.feature-select-multi-dropdown {
    width: 800px;
    z-index: 5;
}

.point-chart-container {
    display: flex;
    justify-content: center;
}

.point-table-container {
    display: flex;
    justify-content: center;
}