.content-container {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 130px);
    margin-left: 2%;
    margin-right: 2%;
    padding-left: 25px;
    padding-right: 25px;
}

.text-container {
    width: 50%;
    padding-top: 30px;
    padding-right: 20px;
}

.image-container {
    padding-top: 20px;
    width: 50%;
}

.home-footer {
    background-color: #dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 100%;
    bottom: 0;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 9999;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    position: relative;
}
