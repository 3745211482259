.summary-page-container {
    display: flex;
    min-height: calc(100vh - 130px);
}

.area-selection {
    width: 20%;
    background-color: #dedede;
    height: calc(100vh - 80px);
    display: block;
    position: fixed;
    padding-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 9999;
    overflow-y: scroll;
}

.area-selection-closed {
    width: 20%;
    left: -20%;
    background-color: #dedede;
    display: block;
    position: fixed;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 9999;
}

.summary-container {
    margin-left: 20%;
    width: 80%;
}

.summary-container-full {
    width: 100%;
}

.content-header {
    text-align: start;
    font-size: 26px;
}

.content {
    text-align: start;
    font-size: 16px;
    color: black;
    width: 100%;
    margin-top: 7px;
    display: inline-block;
}

.toggle-button-container {
    width: 40px;
    height: 40px;
    background-color: #dedede;
    position: fixed;
    left: 20%;
    z-index: 9999;
    padding: 7px 7px;
}

.toggle-button-container-closed {
    width: 40px;
    height: 40px;
    background-color: #dedede;
    position: fixed;
    left: 0;
    z-index: 9999;
    padding: 7px 7px;
}

.summary-header {
    text-align: center;
    font-size: 36px;
    width: 1100px;
}

.summary-dropdown-container {
    display: flex;
    justify-content: space-around;
    padding-left: 200px;
    padding-right: 200px;
}

.summary-datepicker-dropdown {
    width: 300;
    z-index: 9998;
}

.number-container {
    width: 425px;
}

.number-row {
    display: flex;
    justify-content: space-around;
}

.number {
    width: 160px;
    height: 160px;
    background-color:#6200ee;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
}

.number-text {
    font-size: 18px;
    color: white;
    margin: 0;
    padding: 0;
}

.number-number {
    font-size: 48px;
    font-weight: bold;
    color: white;
    margin: 0;
    padding: 0;
}

.dashboard-container {
    display: flex;
    justify-content: space-around;
}

.summary-table-container {
    display: flex;
    justify-content: center;
}

.remark-text {
    padding-left: 5px;
}