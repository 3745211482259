.container {
    display: grid;
    justify-content: center;
    position: static;
}

.header {
    text-align: center;
    font-size: 36px;
    scroll-margin: 100px;
}

.description {
    text-align: justify;
    width: 1100px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
}

.area-dropdown-container {
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 50px;
}

.area-datepicker-dropdown {
    width: 300;
    z-index: 9998;
}

.area-select-dropdown {
    width: 450px;
    z-index: 5;
    margin-top: 25px;
}

.report-creation-table-container {
    display: flex;
    justify-content: center;
}